<template>
  <div class="addAddress">
    <headBack>
      <template v-slot:title>
        <div class='title'>
          {{route.query.addressId?'修改收货地址':'添加收货地址'}}
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class='contenter'>
      <div class='list'>
        <div class='name'>姓名</div>
        <div class='dec'>
          <van-field v-model="userName" class='input_text' placeholder="请输入收货姓名" input-align="right" />
        </div>
      </div>
      <div class='list'>
        <div class='name'>电话</div>
        <div class='dec'>
          <van-field v-model="phone" class='input_text' type="tel" placeholder="请输入收货电话号码" input-align="right" />
        </div>
      </div>
      <div class='list'>
        <div class='name'>选择地区</div>
        <div class='dec' @click="showCityTem">
          {{adressText?adressText:"省，市，区，街道"}}

          <van-icon name="arrow" class='arrow' />
        </div>
      </div>
      <div class='list'>
        <div class='name'>街道/小区</div>
        <div class='dec'>
          <van-field v-model="jiedaovalue" class='input_text' placeholder="街道/小区" input-align="right" @focus='jiedaofocus' ref='jiedao' @update:model-value='jiedaoupdate'/>
        </div>
      </div>

      <div class='list' v-if='baidu_list.length == 0'>
        <div class='name'>详细地址</div>
        <div class='dec'>
          <van-field v-model="detailvalue" class='input_text' placeholder="详细地址" input-align="right" />
        </div>
      </div>
      <!-- <div class='list'>
          <div class='name'>设为默认地址</div>
          <div class='dec'>
              <van-switch v-model="checked" active-color="#00C291" size='25' />
          </div>
      </div> -->
    </div>

    <div class='btn_cont mt80' v-if='baidu_list.length == 0'>
      <!-- <div class='my_button' @click='router.push({name:"addAddress"})'>添加新地址</div> -->
      <van-button type="primary" block round color='#00C291' @click="saveAddress">保存</van-button>
    </div>

    <van-popup v-model:show="state.show" round position="bottom">
      <van-cascader v-model="state.cascaderValue" title="请选择所在地区" :options="options1" active-color="#00C291" @close="state.show = false" @finish="onFinish" @change="onChange" />
    </van-popup>
    <div class='baidu_list' v-if='baidu_list.length > 0'>
      <div class='tips'>您要找的是不是</div>
      <div class='list_item' v-for='(item,index) in baidu_list' :key='index' @click='addressclick(item.address,item.point)'>
        <div class='title'>{{item.title}}</div>
        <div class='dero'>{{item.address}}</div>
      </div>
    </div>
    <div id="container" v-show='false'></div>

  </div>

</template>

<script>
import { onMounted, ref, computed, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from 'axios'
import { Toast } from 'vant';
import jsonp from '@assets/js/jsonp'
import loginApi from "@axios/login";
import shopApi from "@axios/shop";
import BMap from 'BMap'
export default {
  name: "addressEdit",
  components: {},
  requestData: {
    function: "searchAddressDetail",
    provinceId: "",
    cityId: ""
  },
  // options2: [],
  data() {
    return {
      map : '',
      point : {},
      jiedaovalue : '',
      baidu_list : [],
      adressText : '',
      adressArr : [],
      requestData: {
        function: "searchAddressDetail",
        provinceId: "",
        cityId: ""
      },
      options1: [],
      local : '',
      detailvalue : '',
      citycode : '',
      phone:'',
      userName:'',
      districtName :'',
      cityName :'',
      provinceName :'',
    };
  },
  methods: {
    addressclick(val,point){
      let _this = this;
      //ak = uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q
      jsonp(`https://api.map.baidu.com/reverse_geocoding/v3/?ak=QczjWkW4tOMs4ldyAiTGCuX6ehGAxKWW&output=json&location=${point.lat},${point.lng}&callback=baiduCallBack`).then((res)=>{
        _this.citycode = res.result.cityCode;
        this.jiedaovalue = val;
        this.point = point;
        this.baidu_list = [];

      })
    },
    jiedaoupdate(value){
      console.log('ceshi')
      //百度地图筛选
      let _this = this;
      this.local = new BMap.LocalSearch(this.adressText,
          {onSearchComplete(results){
              console.log(results)
              if(results.Br){
                _this.baidu_list = results.Br;
              }
              if(results.Cr){
                _this.baidu_list = results.Cr;
              }
              if(results.Ir){
                _this.baidu_list = results.Ir;
              }
            }});
      this.local.search(this.adressText + value);
    },

    jiedaofocus(){
      if(!this.adressText){
        Toast('请先选择省市区');
        this.$refs.jiedao.blur();
        return
      }
      if(this.local) this.local.search(this.adressText + this.jiedaovalue);
    },
    getCities(formData) {
      loginApi.loginAction(formData).then(res => {

        let data = res.data;
        data.forEach(function(item) {
          item.text = item.name;
          item.value = item.pid;
        });
        this.options1 = data;
        // let options2 = data;


      });
    },
    getAddressDetail(id){
      const params ={
         addressId : id,
         userId : this.$store.state.uid
      }
      const that = this;
      shopApi.getAddressDetail(params).then(res => {

        console.log(res)
         if(res.code == '0000'){
            const data = res.data;
            that.userName= data.consignee;
            that.phone = data.contactNumber;
            that.provinceName = data.provinceName;
            that.cityName = data.cityName;
            that.citycode = data.cityCode;
            that.districtName = data.districtName;
            that.adressText =data.provinceName + data.cityName +data.districtName;
            that.jiedaovalue =data.detailedAddress;
            that.addressDetail = data.addressDetail

        }
      })
    },
    showCityTem(){
      this.state.show =true;
      this.jiedaovalue ='';
      this.detailvalue ='';
    },
    saveAddress(){
      if(!this.userName){
        Toast('请输入收货人姓名');
        return;
      }
      if(!this.phone){
        Toast('请输入收货人手机号');
        return;
      }
      if(!this.adressText){
        Toast('请选择地区');
        return;
      }
      if(!this.jiedaovalue){
        Toast('请输入街道/小区');
        return;
      }

      const params ={
        "userId":this.$store.state.uid,
        "districtName" :this.districtName+ '',
        "cityName" :this.cityName + '',
        "provinceName" :this.provinceName + '',
        "contactNumber" : this.phone + '',
        "consignee":this.userName+'',
        "detailedAddress":this.jiedaovalue,
        "districtCode":'',//区县Code
        "cityCode":this.citycode + '',
        "provinceCode":'',//省份code
        "addressDetail":this.detailvalue,
      }
      if(this.$route.query.addressId){
        console.log(params)
        params.addressId = this.$route.query.addressId
        console.log('编辑')
        shopApi. getAddressUpdateSave(params).then(res => {//编辑
          console.log(res)
          if(res.code == '0000'){
             this.$router.push('/shop/addressList')
          }
        })
      }else{
        shopApi.getAddressAddSave(params).then(res => {//新增
          console.log(res)
          if(res.code == '0000'){
            this.$router.push('/shop/addressList')
          }
        })
      }



    },
    onChange(value) {


      if (value.tabIndex == 0) {
        let requestData = {
          function: "searchAddressDetail",
          provinceId: String(value.value),
          cityId: ""
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(requestData));
        formData.append("encryption", false);
        loginApi.loginAction(formData).then(res => {

          res.data.forEach(function(item) {
            item.text = item.name;
            item.value = item.citycode;
          });

          this.options1[value.value - 1].children = res.data;

        });
      }
      if(value.tabIndex == 1){
        let selecteData = value.selectedOptions;
        let requestData = {
          function: "searchAddressDetail",
          provinceId: "",
          cityId: String(selecteData[1].pid)
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(requestData));
        formData.append("encryption", false);
        loginApi.loginAction(formData).then(res => {

          res.data.forEach(function(item) {
            item.text = item.name;
            item.value = item.pid;
          });


          this.options1[selecteData[0].pid-1].children.forEach(function(item){
            if(item.name == selecteData[1].name){
              item.children = res.data
            }
          })

          // this.options1[value.value - 1].children = res.data;

        });

      }
    },
    onFinish(selectedOptions) {
      console.log(selectedOptions)
      let so = selectedOptions;
      if(so.selectedOptions.length >= 3){
        this.adressArr = so.selectedOptions;
        this.state.show = false;
        this.districtName =so.selectedOptions[2].name,
            this.cityName  = so.selectedOptions[1].name,
            this.provinceName  = so.selectedOptions[0].name,
        this.adressText = so.selectedOptions[0].name + ' ' +so.selectedOptions[1].name + ' ' + so.selectedOptions[2].name
      }

    }
  },
  mounted() {
    if(this.$route.query.addressId){
      const id = this.$route.query.addressId
      this.getAddressDetail(id)
    }

    let formData = new FormData();
    formData.append("data", JSON.stringify(this.requestData));
    formData.append("encryption", false);

    this.getCities(formData);
    this.map = new BMap.Map("container");
    var point = new BMap.Point(116.404, 39.915);
    this.map.centerAndZoom(point, 15);

  },

  setup() {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    let checked = ref(false);

    const state = reactive({
      show: false,
      fieldValue: "",
      cascaderValue: ""
    });
    // 选项列表，children 代表子选项，支持多级嵌套
    let options = [
      {
        text: "浙江省",
        value: "330000",
        children: [{ text: "杭州市", value: "330100" }]
      },
      {
        text: "江苏省",
        value: "320000",
        children: [{ text: "南京市", value: "320100" }]
      }
    ];
    let requestData = {
      function: "searchAddressDetail",
      provinceId: "",
      cityId: ""
    };
    // let options2 = options2;
    // console.log(options2);

    return {
      options,
      route,
      // options2,
      checked,
      state
      //   onFinish
    };
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.tips{
  background: #f8f9fa;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  color: #525a66;
  font-size: 30px;
  padding-left:50px;
  padding-top:20px;
  padding-bottom:20px;
}
.list_item{
  padding:20px 50px;
  .title{
    font-size:35px;
    color: #888;
  }
  .dero{
    color: #888;
    font-size: 30px;
  }
}
.baidu_list{
  width: 100%;
  background: #fff;


}
.input_text {
  width: 400px;
  text-align: right;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #979797;
  padding: 0 !important;
}

.addAddress {
  height: 100%;
  background: #faf9f9;
}
.contenter {
  background: #fff;
  padding: 0 45px;
  box-shadow: 0px 2px 2px 2px rgba(230, 230, 230, 0.2),
  0px 0px 2px 0px rgba(178, 178, 178, 0.12);
  .list {
    padding: 30px 21px;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    .dec {
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrow {
        margin-left: 20px;
      }
    }
  }
}
#container{
  height: 300px;
  height: 300px;
}
</style>
