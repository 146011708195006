import { render } from "./addressEdit.vue?vue&type=template&id=44e96883&scoped=true"
import script from "./addressEdit.vue?vue&type=script&lang=js"
export * from "./addressEdit.vue?vue&type=script&lang=js"

import "./addressEdit.vue?vue&type=style&index=0&id=44e96883&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-44e96883"
/* hot reload */
if (module.hot) {
  script.__hmrId = "44e96883"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('44e96883', script)) {
    api.reload('44e96883', script)
  }
  
  module.hot.accept("./addressEdit.vue?vue&type=template&id=44e96883&scoped=true", () => {
    api.rerender('44e96883', render)
  })

}

script.__file = "src/views/shop/order/address/addressEdit.vue"

export default script